import { render, staticRenderFns } from "./provinceAndCity.vue?vue&type=template&id=9cb389ba&scoped=true&"
import script from "./provinceAndCity.vue?vue&type=script&lang=js&"
export * from "./provinceAndCity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cb389ba",
  null
  
)

export default component.exports