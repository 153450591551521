<!--
 * @Descripttion : 文件上传组件
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-08 17:17:45
 * @LastEditors  : min
 * @LastEditTime : 2022-08-12 12:06:02
-->
<template>
  <div :style="{display:fileObj.name=='其他'||fileObj.name=='车款支付凭证'?'block': 'inline-block'}">
    <!-- <a-upload :action="uploadUrl" list-type="picture-card" :file-list="fileList" :headers="headers" :before-upload="beforeUpload" @preview="handlePreview" @change="handleChange">
      <div v-if="fileList.length < 10">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传文件
          <div class="ml_upload_tips">(最多10个文件)</div>
        </div>
      </div>
    </a-upload> -->

    <div style="text-align:center;">{{fileObj.name}}</div>
    <a-upload :customRequest="customRequest" list-type="picture-card" :file-list="fileList" :headers="headers" :before-upload="beforeUpload" @preview="handlePreview" :remove="removeFile" :accept="fileObj.suffix">

      <template v-if="fileObj.name=='其他'">
        <div v-if="fileList.length <10- fileObj.sort + 1">
          <a-icon type="plus" />
          <div class="ant-upload-text">
            <div class="ml_upload_tips">(剩余{{10 - fileObj.sort + 1- fileList.length}}个文件)</div>
          </div>
        </div>
      </template>
      <template v-else-if="fileObj.name=='车款支付凭证'">
        <div v-if="fileList.length < 6">
          <a-icon type="plus" />
          <div class="ant-upload-text">
            <div class="ml_upload_tips">(剩余{{6 - fileList.length}}个文件)</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="fileList.length < 1">
          <a-icon type="plus" />
          <div class="ant-upload-text">
            <div class="ml_upload_tips">(剩余{{1 - fileList.length}}个文件)</div>
          </div>
        </div>
      </template>
    </a-upload>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" centered>
      <div style="padding-top:15px;">
        <template v-if="previewImage.indexOf('.mp4')>-1">
          <video style="width: 100%" controls>
            <source :src="previewImage" type="video/mp4">
          </video>
        </template>
        <template v-else>
          <img alt="" style="width: 100%" :src="previewImage" />
        </template>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { uploadFilelimit, deleteFile } from "@/api/fileUpload";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const token = localStorage.getItem("cbz_token");

export default {
  props: {
    fileObj: {
      type: Object,
      default: null
    },
    orderNumber: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "files",
      headers: { token: token },
      previewVisible: false,
      previewImage: "",
      fileList: []
    };
  },
  watch: {
    fileObj(n, o) {
      if (n != o) {
        this.fileList = this.fileObj.fileList;
      }
    }
  },
  mounted() {
    this.fileList = this.fileObj.fileList;
    // this.fileObj.fileList.forEach(element => {
    //     if (element.url) {
    //         element.url = element.url + '?x-oss-process=image/resize,w_320,h_240/quality,q_60';
    //     }
    //     this.fileList.push(element);
    // });
  },
  methods: {
    customRequest(file) {
      const that = this;
      that.$emit("onChange", '', 1);
      var formData = new FormData();
      formData.append("file", file.file);
      formData.append("success_action_status", 201); //成功后返回的操作码
      // file.onProgress();
      uploadFilelimit({
        file: file.file,
        fileCode: that.fileObj.code,
        orderNumber: that.orderNumber
      })
        .then(res => {
          // console.log(res);
          if (res.data.url.indexOf("http") > -1) {
            let _file = {
              uid: res.data.fileId,
              name: file.file.name,
              status: "done",
              response: "",
              // url: res.data.url + '?x-oss-process=image/resize,w_320,h_240/quality,q_60',
              url: res.data.url
            };

            that.fileList.push(_file);
            // console.log(that.fileList);
            // file.onSuccess(); //上传成功

            that.$emit("onChange", {
              fileObj: that.fileObj,
              fileList: that.fileList
            });
          } else {
            that.$message.error("文件上传失败！");
          }
        })
        .catch(() => {
          file.onError(); //上传失败
        });
    },
    removeFile(file) {
      const that = this;
      deleteFile(file.uid).then(res => {
        let _array = [];
        that.fileList.forEach(element => {
          if (element.uid != file.uid) {
            _array.push(element);
          }
        });
        that.fileList = _array;
        that.$message.success({
          content: "删除成功",
          duration: 1,
          onClose: () => {
            that.$emit("onChange", {
              fileObj: that.fileObj,
              fileList: that.fileList
            });
          }
        });
      });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ file, fileList }) {
      // 上传结束
      if (file.status) {
        this.fileList = fileList;
      }

      // console.log(fileList);
      // 状态有：uploading done error removed
      if (file.status == "done" || file.status == "removed") {
        if (file.response && file.response.data.indexOf("http") == -1) {
          this.fileList.pop();
        }
        this.$emit("onChange", {
          fileObj: this.fileObj,
          fileList
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    beforeUpload(file) {
      const that = this;
      that.fileObj.volume = that.fileObj.volume ? that.fileObj.volume : 10240;

      const isFileType = file.type ? that.fileObj.suffix.indexOf(file.type) > -1 : false;
      if (!isFileType) {
        this.$message.error("请确保上传格式为" + that.fileObj.suffix);
        return false;
      }

      // console.log(that.fileObj.volume)
      // console.log(file.size)
      const isLtSize = file.size / 1024 < that.fileObj.volume;

      if (!isLtSize) {
        this.$message.error(
          "上传文件不能超过" + parseInt(that.fileObj.volume) / 1024 + "MB!"
        );
        return false;
      }
      return isFileType && isLtSize;
    }
  }
};
</script>
<style lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ml_upload_tips {
  font-size: 12px;
}
</style>