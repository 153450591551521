<!--
 * @Descripttion : 省市选择器
 * @version      : 
 * @Author       : karl
 * @Date         : 2022-07-22 15:41:37
 * @LastEditors  : 
 * @LastEditTime : 
-->
<template>
    <a-cascader :options="options" v-model="defaultProvinceCity" :placeholder="placeholder" :fieldNames="{ label: 'name', value: 'id', children: 'values' }" @change="onChange" :allowClear="false" />
</template>

<script>
import { GetProvinceAndCityAndAreaList } from "@/api/saasV5";

export default {
    props: {
        placeholder: {
            type: String,
            default: "请选择地址"
        },
        selectedProvinceCity: {
            type: Array,
            default: null
        }
    },
    watch: {
        selectedProvinceCity(o, n) {
            console.log(o, n);
            this.defaultProvinceCity = this.selectedProvinceCity;
        }
    },
    data() {
        return {
            options: [],
            defaultProvinceCity: null
        };
    },
    mounted() {
        GetProvinceAndCityAndAreaList({
            json: true
        }).then(res => {
            this.$nextTick(() => {
                // this.options = res.data;
                //重组省市
                res.data.forEach(item => {
                    let _cityArry = [];
                    item.values.forEach(items => {
                        _cityArry.push({
                            id: items.id,
                            name: items.name
                        })
                    })
                    this.options.push({
                        id: item.id,
                        name: item.name,
                        values: _cityArry
                    })
                })
            });
        });
    },
    methods: {
        onChange(value, selectedOptions) {
            // 选中数据返回给父级
            console.log(value, selectedOptions);
            console.log('val:',JSON.stringify(value), JSON.stringify(selectedOptions));
            if (selectedOptions) {
                this.$emit("change", selectedOptions);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
</style>