<!--
 * @Descripttion : 办证资料上传-文件上传
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-08 16:40:40
 * @LastEditors  : min
 * @LastEditTime : 2022-08-16 13:59:33
-->
<template>
  <div>
    <a-tabs default-active-key="2" tab-position="left">
      <a-tab-pane key="2" tab="新车主">

        <div style="padding-top:15px;">
          <span><span style="color:red;margin-right:3px;">*</span>落档城市：</span>
          <selectProvinceCity :selectedProvinceCity="provinceCitySelected" @change="onChangeProvinceCity">
          </selectProvinceCity>

          <span style="padding-left:20px">
            <a-button type="primary" @click="openDragModel(2)">
              图片位置调整
            </a-button>
          </span>
        </div>
        <template v-for="(item, index) in fileConfigNew">
          <div :key="index">
            <a-divider dashed>{{ item.name }}</a-divider>
            <template v-for="(items, indexs) in item.childFileUploadSet">
              <fileUpload :key="indexs" :fileObj="items" :orderNumber="orderNumber" @onChange="uploadDone"></fileUpload>
            </template>
          </div>
        </template>
      </a-tab-pane>

      <a-tab-pane key="1" tab="原车主">
        <a-button type="primary" @click="openDragModel(1)">
          图片位置调整
        </a-button>
        <template v-for="(item, index) in fileConfigOld">
          <div :key="index">
            <a-divider dashed>{{ item.name }}</a-divider>
            <template v-for="(items, indexs) in item.childFileUploadSet">
              <fileUpload :key="indexs" :fileObj="items" :orderNumber="orderNumber" @onChange="uploadDone"></fileUpload>
            </template>
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>

    <div class="ml_upload_save">
      <a-divider dashed />
      <a-button type="primary" size="default" icon="save" @click="uploadOrderFile">
        保 存
      </a-button>
    </div>

    <div v-if="uploading" style="position:fixed;top:0;right:0;bottom:0;left:0;z-index:1000;height:100%;background-color:rgba(0,0,0,.45);">
      <a-spin tip="上传中..." size="large" style="position:absolute;top:50%;left:50%;color:#fff;"></a-spin>
    </div>

    <a-modal :visible="draggableVisible" title="位置调整" centered @cancel="handleCancel" @ok="handleOk" :width="820">
      <div class="ml_grid">
        <template v-for="(item, index) in draggableList">
          <!-- @dragstart="handleDragStart()" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event)" @dragend="handleDragEnd($event)" -->
          <div class="ml_grid_item" :key="index">
            <a-divider dashed>{{ item.name }}</a-divider>
            <template v-for="(items, indexs) in item.childFileUploadSet">
              <template v-if="items.name == '其他'">
                <div style="display:block;" :key="indexs">
                  <div class="ml_img_sort_title">{{ items.name }}</div>
                  <div class="ml_img_sort" style="width:100%;min-height:60px;" @dragstart="handleDragStart()" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event,items,idx)" @dragend="handleDragEnd($event)">
                    <template v-if="items.fileList && items.fileList.length">
                      <template v-for="(ite, idx) in items.fileList">
                        <div class="ml_img_sort" style="display: inline-block;vertical-align:top;" :key="idx">
                          <div class="ml_img_sort_img_content" draggable="true" @dragstart.stop="handleDragStart(items,idx)" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event, items,idx)" @dragend.stop="handleDragEnd($event, items,idx)">
                            <img :src="ite.url" alt="" class="ml_img_sort_img" />
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="ml_img_sort_img_content" @dragstart.stop="handleDragStart(items,idx)" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event, items,idx)" @dragend.stop="handleDragEnd($event, items,idx)"></div>
                    </template>
                  </div>
                </div>
              </template>
              <template v-else>
                <div style="display: inline-block;vertical-align:top;" :key="indexs">
                  <div class="ml_img_sort_title">{{ items.name }}</div>
                  <!-- @dragstart.stop="handleDragStart()" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event,items,indexs)" @dragend="handleDragEnd($event)" -->
                  <div class="ml_img_sort" style="min-height:60px;">
                    <template v-if="items.fileList && items.fileList.length">
                      <div v-for="(ite, idx) in items.fileList" :key="idx">
                        <div class="ml_img_sort_img_content" draggable="true" @dragstart.stop="handleDragStart(items,idx)" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event, items,idx)" @dragend.stop="handleDragEnd($event, items,idx)">
                          <img :src="ite.url" alt="" class="ml_img_sort_img" />
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="ml_img_sort_img_content" @dragstart="handleDragStart(items,idx)" @dragover.prevent="handleDragOver($event)" @dragenter="handleDragEnter($event, items,idx)" @dragend.stop="handleDragEnd($event, items,idx)"></div>
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </template>
      </div>
    </a-modal>

  </div>
</template>
<script>
import { getFileConfigAll, getOrderFile } from "@/api/fileUpload";
import { uploadOrderFile } from "@/api/order";
import fileUpload from "./component-upload";
import selectProvinceCity from "@/components/component-province-city/provinceAndCity";

// import Sortable from "sortablejs";

export default {
  components: {
    fileUpload,
    selectProvinceCity
  },
  props: {
    orderObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fileConfigOld: [],
      fileConfigNew: [],
      orderNumber: "",
      isErp: false,
      isLMP: false,
      uploading: false,
      provinceCitySelected: [],
      settleArchivesCityCode: null,
      settleArchivesCityName: '',
      settleArchivesProvinceCode: null,
      settleArchivesProvinceName: '',

      // 是否打开调整位置
      draggableVisible: false,
      draggableTab: null,
      //   排序信息
      draggableList: [],
      ending: null,
      endindex: null,
      dragging: null,
      draggindex: null

    };
  },
  created() {
    this.orderNumber = this.$route.query.orderno;
    // 是否来源于erp
    if (
      sessionStorage.getItem("cbz_appid") &&
      sessionStorage.getItem("cbz_timestamp")
    ) {
      this.isErp = true;
    } else {
      this.isErp = false;
    }

    // 是否来自原联盟拍
    if (this.$route.query.source == "lmp") {
      this.isLMP = true;
    } else {
      this.isLMP = false;
    }
    if (!this.isErp && !this.isLMP) {
      // this.getFileConfig();
      this.getOrderFile();
    }
  },
  methods: {
    handleCancel() {
      // 隐藏更换位置弹窗
      this.draggableVisible = !this.draggableVisible;
    },
    handleOk() {
      // 更换位置确定
      const that = this;
      if (that.draggableTab == 2) {
        that.fileConfigNew = that.draggableList;
      } else {
        that.fileConfigOld = that.draggableList;
      }
      that.draggableList = [];
      that.draggableVisible = false;
      that.$forceUpdate();
    },
    openDragModel(e) {
      const that = this;
      that.draggableVisible = true;
      that.draggableTab = e;
      if (that.draggableTab == 2) {
        that.draggableList = JSON.parse(JSON.stringify(that.fileConfigNew));
      } else {
        that.draggableList = JSON.parse(JSON.stringify(that.fileConfigOld));
      }
      // console.log(JSON.stringify(that.draggableList));
    },
    handleDragStart(item, index) {
      if (item) {
        this.dragging = item;
        this.draggindex = index;
      }
    },
    handleDragEnd(e, item, index) {
      const that = this;
      if (that.draggindex != null && that.endindex != null) {
        let _draggInfo = that.dragging.fileList[that.draggindex];
        that.dragging.fileList.splice(that.draggindex, 1);
        that.dragging.uploadList.splice(that.draggindex, 1);

        let _endInfo = null;

        if (that.ending.fileList && that.ending.fileList.length) {
          _endInfo = that.ending.fileList[that.endindex];
          that.ending.fileList.splice(that.endindex, 1);
          that.ending.uploadList.splice(that.endindex, 1);
        }
        if (_endInfo) {
          that.dragging.fileList.splice(that.draggindex, 0, _endInfo);
          that.dragging.uploadList.splice(that.draggindex, 0, _endInfo.url);
        }
        that.ending.fileList.splice(that.endindex, 0, _draggInfo);
        that.ending.uploadList.splice(that.endindex, 0, _draggInfo.url);

      } else if (that.draggindex != null && that.endindex == null) {
        let _draggInfo = that.dragging.fileList[that.draggindex];
        that.dragging.fileList.splice(that.draggindex, 1);
        that.dragging.uploadList.splice(that.draggindex, 1);

        that.ending.fileList.push(_draggInfo);
        that.ending.uploadList.push(_draggInfo.url);
      } else if (that.endindex != null && that.draggindex == null) {
        let _endInfo = that.ending.fileList[that.endindex];
        that.ending.fileList.splice(that.endindex, 1);
        that.ending.uploadList.splice(that.endindex, 1);

        that.dragging.fileList.push(_endInfo);
        that.dragging.uploadList.push(_endInfo.url);
      }
      // console.log(that.draggindex, that.endindex);
      // console.log(that.dragging, that.ending);
    },
    handleDragOver(e) {
      // 首先把div变成可以放置的元素，即重写dragenter/dragover
      // 在dragenter中针对放置目标来设置
      e.dataTransfer.dropEffect = "move";
    },
    handleDragEnter(e, item, index) {
      // 为需要移动的元素设置dragstart事件
      e.dataTransfer.effectAllowed = "move";
      if (item) {
        this.ending = item;
        this.endindex = index;
      }
    },
    getOrderFile() {
      const that = this;
      getOrderFile(that.orderObj.orderNumber).then(res => {
        res.data.forEach(element => {
          element.childFileUploadSet.forEach(item => {
            item["fileList"] = [];
            item["uploadList"] = [];
            item.fileInfo.forEach(items => {
              item.fileList.push({
                uid: items.id,
                name: items.fileName,
                status: "done",
                response: "",
                url: items.url
              });
              item.uploadList.push(items.url);
            });
          });
          if (element.type == "ceroldown") {
            that.fileConfigOld.push(element);
          } else {
            that.fileConfigNew.push(element);
          }
        });

        //省市赋值
        that.provinceCitySelected = [];
        if (that.orderObj.settleArchivesProvinceCode && that.orderObj.settleArchivesCityCode) {
          setTimeout(function () {
            that.provinceCitySelected.push(String(that.orderObj.settleArchivesProvinceCode));
            that.provinceCitySelected.push(String(that.orderObj.settleArchivesCityCode));
            that.settleArchivesProvinceCode = that.orderObj.settleArchivesProvinceCode;
            that.settleArchivesProvinceName = that.orderObj.settleArchivesProvinceName;
            that.settleArchivesCityCode = that.orderObj.settleArchivesCityCode;
            that.settleArchivesCityName = that.orderObj.settleArchivesCityName;
          }, 500);
        }
      });
    },
    getFileConfig() {
      // 获取文件上传配置
      const that = this;
      getFileConfigAll().then(res => {
        res.data.forEach(element => {
          element["fileList"] = [];
          element["uploadList"] = [];

          if (that.orderObj) {
            if (that.orderObj.newCertificationFile) {
              that.orderObj.newCertificationFile.forEach(item => {
                if (item.code == element.code) {
                  //   element.fileList.push({
                  //     uid: res.data.fileId,
                  //     name: file.file.name,
                  //     status: "done",
                  //     response: "",
                  //     url: res.data.url
                  //   });
                  item.fileInfo.forEach(items => {
                    element.fileList.push({
                      uid: items.id,
                      name: items.fileName,
                      status: "done",
                      response: "",
                      url: items.url
                    });
                    element.uploadList.push(items.url);
                  });
                }
              });
            }
            if (that.orderObj.oldCertificationFile) {
              that.orderObj.oldCertificationFile.forEach(item => {
                if (item.code == element.code) {
                  item.fileInfo.forEach(items => {
                    element.fileList.push({
                      uid: items.id,
                      name: items.fileName,
                      status: "done",
                      response: "",
                      url: items.url
                    });
                    element.uploadList.push(items.url);
                  });
                }
              });
            }
          }

          if (element.type == "ceroldown") {
            that.fileConfigOld.push(element);
          } else {
            that.fileConfigNew.push(element);
          }
        });
      });
    },
    uploadDone(e, type) {
      // 图片上传完成回调
      // e: fileObj fileList
      const that = this;
      if (type == 1) {
        that.uploading = true;
      } else {
        setTimeout(() => {
          that.uploading = false;
        }, 1000)
        let uploadImgList = [];
        e.fileList.forEach(element => {
          uploadImgList.push(element.url);
        });
        //   console.log(uploadImgList);
        if (e.fileObj.type == "ceroldown") {
          //   原车主
          that.fileConfigOld.forEach(item => {
            item.childFileUploadSet.forEach(element => {
              if (element.code == e.fileObj.code) {
                //   获取修改的类型
                element.uploadList = uploadImgList;
                element.fileList = e.fileList;
              }
            });
          });
        } else {
          //   新车主
          that.fileConfigNew.forEach(item => {
            item.childFileUploadSet.forEach(element => {
              if (element.code == e.fileObj.code) {
                //   获取修改的类型
                element.uploadList = uploadImgList;
                element.fileList = e.fileList;
              }
            });
          });
        }
      }
    },
    uploadOrderFile() {
      // 上传文件信息
      const that = this;
      let formData = {
        businessId: that.orderNumber,
        json: true,
        childList: [],
        settleArchivesCityCode: that.settleArchivesCityCode,
        settleArchivesCityName: that.settleArchivesCityName,
        settleArchivesProvinceCode: that.settleArchivesProvinceCode,
        settleArchivesProvinceName: that.settleArchivesProvinceName
      };
      if (!that.settleArchivesCityCode) {
        that.$message.error({
          content: "请选择落档城市",
          duration: 1,
        });
        return false;
      }

      // console.log('原车主：' + JSON.stringify(that.fileConfigOld))
      // console.log('新车主：' + JSON.stringify(that.fileConfigNew))

      for (let i = 0; i < that.fileConfigOld.length; i++) {
        for (let j = 0; j < that.fileConfigOld[i].childFileUploadSet.length; j++) {
          if (
            that.fileConfigOld[i].childFileUploadSet[j].isRequired == 1 &&
            that.fileConfigOld[i].childFileUploadSet[j].uploadList.length == 0
          ) {
            that.$message.error({
              content: "请上传原车主" + that.fileConfigOld[i].childFileUploadSet[j].name,
              duration: 1,
            });
            return false;
          }

          if (that.fileConfigOld[i].childFileUploadSet[j].uploadList.length > 0) {
            formData.childList.push({
              fileCode: that.fileConfigOld[i].childFileUploadSet[j].code,
              fileUrlList: that.fileConfigOld[i].childFileUploadSet[j].uploadList,
              type: that.fileConfigOld[i].childFileUploadSet[j].type
            });
          }
        }
      }

      for (let i = 0; i < that.fileConfigNew.length; i++) {
        for (let j = 0; j < that.fileConfigNew[i].childFileUploadSet.length; j++) {
          if (
            that.fileConfigNew[i].childFileUploadSet[j].isRequired == 1 &&
            that.fileConfigNew[i].childFileUploadSet[j].uploadList.length == 0
          ) {
            that.$message.error({
              content: "请上传新车主" + that.fileConfigNew[i].childFileUploadSet[j].name,
              duration: 1,
              onClose: () => { }
            });

            return false;
          }

          if (that.fileConfigNew[i].childFileUploadSet[j].uploadList.length > 0) {
            formData.childList.push({
              fileCode: that.fileConfigNew[i].childFileUploadSet[j].code,
              fileUrlList: that.fileConfigNew[i].childFileUploadSet[j].uploadList,
              type: that.fileConfigNew[i].childFileUploadSet[j].type
            });
          }
        }
      }

      that.$confirm({
        content: "是否确认提交？",
        centered: true,
        onOk() {
          uploadOrderFile(formData).then(res => {
            that.$message.success({
              content: "提交成功",
              duration: 1,
              onClose: () => {
                that.$emit("onSave");
              }
            });
          });
        },
        onCancel() { }
      });
    },
    onChangeProvinceCity(selectedOptions) {
      this.settleArchivesProvinceCode = parseInt(selectedOptions[0].id);
      this.settleArchivesProvinceName = selectedOptions[0].name;
      this.settleArchivesCityCode = parseInt(selectedOptions[1].id);
      this.settleArchivesCityName = selectedOptions[1].name;
    },
  }
};
</script>
<style lang="scss" >
.ml_upload_save {
  text-align: center;
}

.ant-spin-dot-item {
  background-color: #fff;
}

.ml_img_sort_title {
  padding: 10px 0;
  text-align: center;
}

.ml_img_sort {
  border: 1px dashed #979797;
  padding: 10px 10px;

  .ml_img_sort_img_content {
    width: 70px;
    height: 60px;
    padding: 0 5px;

    .ml_img_sort_img {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}

.ml_grid {
  display: grid;
  grid-template-columns: 50% 50%;
  -webkit-column-gap: 10px;

  .ml_grid_item {
  }
}
</style>
