<!--
 * @Descripttion : 订单详情-包含操作按钮
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-28 10:36:04
 * @LastEditors  : min
 * @LastEditTime : 2022-03-18 11:27:32
-->
<template>
    <div class="ml_order_detail">
        <a-tabs :v-model="(orderObj.pickUpCarStatus > 0 ? '0' : tabIndex)">
            <template slot="tabBarExtraContent">
                <span @click="telPhone">
                    400-022-6698
                </span>
            </template>

            <a-tab-pane key="0" tab="办证提车" v-if="orderObj.pickUpCarStatus > 0">
                <pickUpCarInfoDetail :orderObj="orderObj"></pickUpCarInfoDetail>
            </a-tab-pane>

            <a-tab-pane key="1" tab="办证详情">

                <orderBase :orderObj="orderObj"></orderBase>

                <!-- 1:办证中，2:平台待审核，3：机构待审核，4，已完结 -->
                <template v-if="isErp || isLMP">
                    <orderFileList :orderObj="orderObj"></orderFileList>
                </template>
                <template v-else>
                    <template
                        v-if="orderObj.state == 1 || (orderObj.state == 2 && isEdit) || orderObj.state == 5 || orderObj.state == 6">
                        <!-- 编辑 -->
                        <orderFileUpload :orderObj="orderObj" @onSave="onUploadSave"></orderFileUpload>
                    </template>
                    <template v-else>
                        <orderFileList :orderObj="orderObj"></orderFileList>
                    </template>
                </template>

                <div style="display:none;" :data-isLMP="isLMP" :data-isErp="isErp" :data-state="orderObj.state"></div>
                <!--debug-->

                <!-- 按钮操作 -->
                <template v-if="!isLMP">
                    <template v-if="isErp">
                        <!--永达客制化cannotOP=1不显示操作按锯-->
                        <template v-if="cannotOP != 1">
                            <!-- erp  -->
                            <div style="height:40px"></div>
                            <div class="ml_handle_button ml_handle_button_erp" v-if="orderObj.state == 3">
                                <a-row v-if="isPhone">
                                    <a-col :span="12">
                                        <a-button type="primary" @click="onAdopt" block size="large">
                                            通过
                                        </a-button>
                                    </a-col>
                                    <a-col :span="12">
                                        <a-button type="danger" @click="modalVisible = true" block size="large">
                                            驳回
                                        </a-button>
                                    </a-col>
                                </a-row>
                                <a-row v-else style="padding:10px 0">
                                    <a-space size="large">
                                        <a-button type="primary" @click="onAdopt" size="large" style="width:200px">
                                            通过
                                        </a-button>

                                        <a-button type="danger" @click="modalVisible = true" size="large"
                                            style="width:200px">
                                            驳回
                                        </a-button>
                                    </a-space>
                                </a-row>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <!-- 系统内部 -->
                        <div class="ml_handle_button" v-if="orderObj.state == 2 && !isEdit">
                            <a-space size="large">
                                <a-button type="primary" @click="onAdopt">
                                    通过
                                </a-button>
                                <a-button type="danger" @click="modalVisible = true">
                                    驳回
                                </a-button>
                                <a-button @click="onCancel">取消</a-button>
                            </a-space>
                        </div>
                    </template>
                </template>
            </a-tab-pane>

            <a-tab-pane key="2" tab="办证轨迹">
                <orderTrail :orderObj="orderObj"></orderTrail>
            </a-tab-pane>

            <a-tab-pane key="3" tab="证件核对"
                v-if="orderObj.invoiceCheckStatus != null && (orderObj.invoiceCheckStatus == 3 || orderObj.invoiceCheckStatus == 2)">
                <!-- <orderInvoice :orderObj="orderObj" @onSave="onUploadSave"></orderInvoice> -->
                <orderCertificate :orderObj="orderObj"></orderCertificate>
            </a-tab-pane>
        </a-tabs>

        <a-modal v-model="modalVisible" title="驳回备注" centered @ok="onReject">
            <a-textarea v-model="rejectMark" placeholder="请输入驳回备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
        </a-modal>
    </div>
</template>
<script>
import orderFileList from "../component-file/component-file-list";
import orderFileUpload from "../component-file/component-file-upload";
import orderBase from "./component-order-detaile-base"; //  订单基础信息
import orderTrail from "./component-order-detaile-trail"; //  订单轨迹
// import orderInvoice from "./component-order-detaile-invoice"; //  证件核对
import orderCertificate from "./component-order-detaile-certificate-verification"; //证件核对
import pickUpCarInfoDetail from "@/components/pickUpCar/pickUpCarInfoDetail"; //办证提车详情

import { orderAudit, orderExteriorAudit } from "@/api/order";

export default {
    name: "orderdetail",
    components: {
        orderFileList,
        orderFileUpload,
        orderBase,
        orderTrail,
        orderCertificate,
        pickUpCarInfoDetail
    },
    props: {
        orderObj: {
            type: Object,
            default: null
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tabIndex: "1",
            modalVisible: false,
            rejectMark: "",
            orderNumber: "",
            isErp: false,
            isPhone: false,
            isLMP: false,
            configs: {
                width: 500,
                height: 500,
                maskWidth: 100,
                maskColor: "red",
                maskOpacity: 0.2
            },
            cannotOP: null,
        };
    },
    mounted() {
        this.orderNumber = this.$route.query.orderno;

        // 是否来源于erp
        if (
            sessionStorage.getItem("cbz_appid") &&
            sessionStorage.getItem("cbz_timestamp")
        ) {
            this.isErp = true;
        } else {
            this.isErp = false;
        }

        // 是否来自原联盟拍、saas(ishidebutton)
        if (this.$route.query.source == "lmp" || this.$route.query.ishidebutton == 1) {
            this.isLMP = true;
        } else {
            this.isLMP = false;
        }

        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            //移动端
            this.isPhone = true;
        }

        this.cannotOP = this.$route.query.cannotOP;
        console.log('cannotOP', this.$route.query.cannotOP)


    },
    methods: {
        telPhone() {
            location.href = "tel:400-022-6698";
        },
        onUploadSave() {
            this.$emit("onSave");
        },
        onAdopt() {
            // 通过
            const that = this;
            that.$confirm({
                title: "温馨提示",
                content: "是否确认通过？",
                centered: true,
                onOk() {
                    that.orderAudit(1);
                },
                onCancel() { }
            });
        },
        onReject() {
            const that = this;
            if (that.rejectMark) {
                this.$confirm({
                    title: "温馨提示",
                    content: "是否确认驳回？",
                    centered: true,
                    onOk() {
                        that.modalVisible = false;
                        that.orderAudit(2);
                    },
                    onCancel() { }
                });
            } else {
                that.$message.error({
                    content: "请输入驳回备注",
                    duration: 1,
                    onClose: () => { }
                });
            }
        },
        onCancel() {
            this.$router.go(-1);
        },
        orderAudit(state) {
            const that = this;
            if (that.$route.query.token && that.$route.query.appid) {
                //  外部erp
                let message = "",
                    _state = "";
                if (state == 1) {
                    message = "订单通过成功";
                    _state = 3;
                } else {
                    message = "订单驳回成功";
                    _state = 4;
                }
                orderExteriorAudit({
                    json: true,
                    auditRemark: that.rejectMark,
                    auditSource: "ERP", //  cer:办证系统审核，lmp：联盟拍审核，erp：ERP审核
                    auditUserPhone: that.$route.query.phone,
                    auditUserRealName: that.$route.query.username,
                    businessId: that.orderNumber,
                    businessType: "cerorder",
                    appId: that.$route.query.appid,
                    state: _state //  3:机构通,4：机构驳回
                }).then(res => {
                    console.log(res);

                    that.$message.success({
                        content: message,
                        duration: 1,
                        onClose: () => {
                            that.$emit("onSave");
                        }
                    });
                });
            } else {
                //   内部审核
                let message = "";
                if (state == 1) {
                    message = "订单通过成功";
                } else {
                    message = "订单驳回成功";
                }
                orderAudit({
                    json: true,
                    auditRemark: that.rejectMark,
                    auditSource: "cer", //  cer:办证系统审核，lmp：联盟拍审核，erp：ERP审核
                    auditUserPhone: "",
                    auditUserRealName: "",
                    businessId: that.orderNumber,
                    businessType: "cerorder",
                    operateCity: "",
                    operateIp: "",
                    operateUserId: 0,
                    operateUserName: "",
                    source: "",
                    state: state //  1:平台审批通过，2：平台审批驳回
                }).then(res => {
                    console.log(res);

                    that.$message.success({
                        content: message,
                        duration: 1,
                        onClose: () => {
                            that.$emit("onSave");
                        }
                    });
                });
            }
        }
    }
}
</script>
<style lang="scss">
.ml_order_detail {
    .ml_handle_button {
        text-align: center;
        padding: 20px 0;
    }

    .ml_handle_button_erp {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        padding: 0;
        box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
    }

    .ml_order_table {
        background-color: #fafafa;
        padding: 10px 10px 2px 10px;
    }

    .ml_order_des {
        background-color: #fafafa;
        padding: 10px;
        border-radius: 2px;
    }

    .ml_order_title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
        font-size: 16px;
        line-height: 1.5;
        padding: 20px 0 10px 0;
    }

    .ant-descriptions {
        .ant-descriptions-title {
            margin: 0;
            padding: 20px 0 10px 0;
        }

        .ant-descriptions-item-label {
            width: 100px;
        }
    }

    .ml_order_card {
        display: inline-block;
        width: 250px;
        margin: 0 10px 10px 0;
        vertical-align: top;

        .ml_order_img {
            width: 100%;
            height: 180px;
            object-fit: cover;
            text-align: center;
            line-height: 180px;
            overflow: hidden;

            .ml_order_icon {
                width: 50px;
                margin: 0 auto;
                background-color: #fafafa;
            }
        }

        .ant-card-body {
            padding: 10px;
        }
    }
}
</style>